import * as React from "react"


export default function HeaderWrapper({featuredImageUrl, title}) {

const styles = {
	backgroundImageStyle: {
		backgroundImage: `url(${featuredImageUrl})`,
		backgroundSize: "cover",
   		backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        opacity: "0.85"
	},
	backgroundColorStyle: {
	    background: "#00000091",
	    height: "92vh"
	}
}

	return(
	<div>
		<div 
			className="" 
			style={styles.backgroundImageStyle}
		>

			<div 
				className=" flex items-center place-content-center" 
				style={styles.backgroundColorStyle}
			>
				<h1 className="text-3xl m:text-xl text-white md:text-4xl lg:text-5xl xl:text-5xl">
					{title}
				</h1>
			</div>
		</div>
	</div>
)
}

